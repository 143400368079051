import { inject, isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  createReducer,
  createSelector,
  MetaReducer,
  on
} from '@ngrx/store';
import { createAction, props } from '@ngrx/store';
import { ApiMessagesResponse, Message } from '../modules/shared/interfaces/messages';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessagesApiService } from '../core/services/messages-api.service';
import { catchError, combineLatest, EMPTY, exhaustMap, map, of } from 'rxjs';

export interface MessageProps { messages?: Array<Message>, count?: number };

export interface State {
  inbox: Array<Message>,
  trash: Array<Message>,
  unread: number;
  type: 'inbox' | 'trash';
  message: Message | null
}

export const initialState: State = {
  inbox: [],
  trash: [],
  unread: 0,
  type: 'inbox',
  message: null
};

export const MessageActions = {
  deleteMessages: '[Messages] Delete',
  deleteMessagesError: '[Messages] Delete Messages Error',
  initMessages: '[App] Load All Messages',
  loadMessages: '[Messages] Load',
  loadMessagesError: '[Messages] Load Messages Error',
  setInboxMessages: '[Inbox] Messages',
  setTrashMessages: '[Trash] Messages',
  setUnreadMessages: '[Notifications] Count'
}

export const getMessages = createAction(
  MessageActions.loadMessages);

export const getMessagesError = createAction(
  MessageActions.loadMessagesError,
  props<{ message: string }>());

export const deleteMessages = createAction(
  MessageActions.deleteMessages);

export const deleteMessagesError = createAction(
  MessageActions.deleteMessagesError,
  props<{ message: string }>());

export const resetMessages = createAction(
  MessageActions.initMessages,
  props<{ inbox: Array<Message>, trash: Array<Message> }>()
);

export const setMessage = createAction(
  MessageActions.setInboxMessages,
  props<{ message: Message }>()
);

export const setInboxMessages = createAction(
  MessageActions.setInboxMessages,
  props<MessageProps>()
);

export const setTrashMessages = createAction(
  MessageActions.setTrashMessages,
  props<MessageProps>()
);

export const setUnreadMessages = createAction(
  MessageActions.setUnreadMessages,
  props<MessageProps>()
);

export const messageReducer = createReducer(
  initialState,
  on(setMessage, (state, { message }) => ({ ...state, message })),
  on(setInboxMessages, (state, { messages }) => ({ ...state, inbox: messages })),
  on(setTrashMessages, (state, { messages }) => ({ ...state, inbox: messages })),
  on(setUnreadMessages, (state, { count }) => ({ ...state, unread: count })),
  on(resetMessages, (state, { inbox, trash }) => ({ ...state, inbox, trash })),
);

export const loadMessages = createEffect(
  (actions$ = inject(Actions), service = inject(MessagesApiService)) => {
    return actions$.pipe(
      ofType(getMessages),
      exhaustMap(() =>
        combineLatest([service.getMessages('inbox'), service.getMessages('deleted')])
          .pipe(map(([inboxResponse, trashResponse]: Array<ApiMessagesResponse>) => resetMessages({ inbox: inboxResponse.data, trash: trashResponse.data })),
            catchError((error) => of(getMessagesError({ message: error.message }))))
      )
    )
  }, { functional: true }
)

export const selectUnread = () => createSelector(
  (state: State) => state.unread, (counter) => counter
);

export const selectMessage = () => createSelector(
  (state: State) => state.inbox, (messages) => messages
);

export const selectTrash = () => createSelector(
  (state: State) => state.trash, (messages) => messages
);

export const selectMessagesIds = () => createSelector(
  (state: State) => state[state.type], (messages) => messages?.reduce?.((acc: Array<string>, message: Message) => {
    if (!message.selected) return acc;
    return [...acc, message.id];
  }, []) ?? []
);

export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];