import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

export enum Breakpoints {
  'HANDHELD' = 'handheld',
  'TABLET' = 'tablet',
  'DESKTOP' = 'desktop',
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointObserverService {
  handheldBreakpoint = ['(max-width:768px)'];
  tabletBreakpoint = ['(min-width:769px) and (max-width:1024px)'];
  desktopBreakpoint = ['(min-width:1025px)'];

  public screenSizeObserver = new BehaviorSubject<Breakpoints[]>([Breakpoints.TABLET]);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initObservers();
  }

  private initObservers() {
    this.breakpointObserver.observe(this.handheldBreakpoint).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.screenSizeObserver.next([Breakpoints.HANDHELD]);
      }
    });
    this.breakpointObserver.observe(this.tabletBreakpoint).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.screenSizeObserver.next([Breakpoints.HANDHELD, Breakpoints.TABLET]);
      }
    });
    this.breakpointObserver.observe(this.desktopBreakpoint).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.screenSizeObserver.next([Breakpoints.HANDHELD, Breakpoints.TABLET, Breakpoints.DESKTOP]);
      }
    });
  }
}
