import { NgModule } from '@angular/core';


//import {RegistrationComponent} from './registration/registration.component';
//import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
//import {LogoutComponent} from './logout/logout.component';



@NgModule({})
export class AuthRoutingModule {}
