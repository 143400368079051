import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { timer, takeUntil, Subject } from 'rxjs';
import { AuthService } from '../../auth';

export interface LoadingProps {
  header?: string;
  message?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ActivityService implements OnDestroy {
  /* Dont fire every event only store after 1 second of clicks, mouse moves, etc */
  minDebounceEvents = 1000;
  minSetActivity = 3000;
  maxInactiveTime = window.location.origin.includes('localhost') ? 1200000 : 600000;
  activity$ = new Subject<void>();
s
  timeOut$ = new Subject<void>();
  registerActivity$ = new Subject<void>();
  lastActiveTime: number = new Date().getTime();
  warned: boolean;

  scroll = new Subject<boolean>();
  scroll$ = this.scroll.asObservable();

  get now(): number {
    return new Date().getTime();
  }

  get lastActive(): number {
    const storedActiveTime = localStorage.getItem('lastActiveTime') ?? this.now;
    const lastActiveTime = storedActiveTime ? +storedActiveTime : 0;
    return lastActiveTime;
  }

  private minLastActivity = this.now;

  constructor(
    private readonly auth: AuthService,
    private toastr: ToastrService,
  ) {
    this.initActivity();
  }

  ngOnDestroy(): void {
    this.timeOut$.next();
    this.timeOut$.complete();
  }

  initActivity(): void {
    localStorage.setItem('lastActiveTime', `${this.now}`);
    this.startTimers();
  }

  startTimers(): void {
    this.startActivitySubscription();
    this.startActivityTimer();
  }

  getInactiveTime(): void {
    /*
      When comparing if the last active time was before the initialization
      compare to time of initialization because this means the user was redirected
    */
    const lastActiveTime = this.lastActive;
    const diff = new Date().getTime() - Math.max(lastActiveTime, this.minLastActivity);
    if (diff >= this.maxInactiveTime - 3000 && !this.warned) {
      this.warned = true;
      this.timeOut$.next();
      this.toastr.error('You are being logged out due to inactivity');
      timer(3000).subscribe(() => {
        this.clearTimers();
      });
    }
  }

  setTimeSinceActivity(): void {
    const lastActiveTime = this.lastActive;
    const d = new Date(this.now);
    localStorage.setItem(
      'activityChecked',
      `${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(-2)}:${`0${d.getSeconds()}`.slice(-2)}`
    );
    const mins = (this.maxInactiveTime / 1000 - Math.ceil((this.now - lastActiveTime) / 1000)) / 60;
    const mm = Math.floor(mins);
    const secs = (this.maxInactiveTime / 1000 - Math.ceil((this.now - lastActiveTime) / 1000)) % 60;
    const ss = Math.ceil(secs);
    localStorage.setItem('timeSinceActivity', `${`0${mm}`.slice(-2)}:${`0${ss}`.slice(-2)}`);
  }

  clearTimers(): void {
      this.timeOut$.next();
      this.timeOut$.complete();
      this.auth.logout();
  }

  setLastActivity(): void {
    localStorage.setItem('lastActiveTime', `${this.now}`);
  }

  startActivityTimer(): void {
    timer(0, this.minDebounceEvents)
      .pipe(takeUntil(this.timeOut$))
      .subscribe(() => {
        /* Only start time if the user is logged in */
        this.getInactiveTime();
      });
  }

  startActivitySubscription(): void {
    this.registerActivity$.pipe(takeUntil(this.timeOut$)).subscribe(() => {
      this.setLastActivity();
    });
  }

}
