import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBarModule as NgxLoadingBarModule } from '@ngx-loading-bar/core';

import { BodyLogoComponent } from './body-logo/body-logo.component';
import { HorizontalLineComponent } from './horizontal-line/horizontal-line.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { CasePipe } from './pipes/case.pipe';
import { NavigationService } from './services/navigation.service';
import { ActivityService } from './services/activity.service';


@NgModule({
  declarations: [
    BodyLogoComponent,
    HorizontalLineComponent,
    LoadingBarComponent,
    MainFooterComponent,
    CasePipe,
  ],
  imports: [
    CommonModule,
    NgxLoadingBarModule,
  ],
  exports: [
    BodyLogoComponent,
    HorizontalLineComponent,
    LoadingBarComponent,
    MainFooterComponent,
    CasePipe
  ],
  providers: [NavigationService, ActivityService]
})
export class SharedModule { }
