import { environment } from 'src/environments/environment';

const { b2cUrl, clientId, appUrl } = environment;
export const urlConst: IUrlConst = {
  change_pass: `${b2cUrl}/authorize?p=b2c_1a_changepasswordusingphone&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${appUrl}%2Fauth%2Fexternal&scope=openid&response_type=id_token&prompt=login`,
  update_email: `${b2cUrl}/authorize?p=b2c_1a_changeemailusingphone&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${appUrl}%2Fauth%2Fexternal&scope=openid&response_type=id_token&prompt=login`,
  update_phone: `${b2cUrl}/authorize?p=b2c_1a_changephoneusingphone&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${appUrl}%2Fauth%2Fexternal&scope=openid&response_type=id_token&prompt=login`,
  signin_complete_profile: `${b2cUrl}/authorize?p=B2C_1A_PCUSTNAP_SigninPhoneCompleteProfile&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${appUrl}%2Fauth%2Fexternal&scope=openid&response_type=id_token&prompt=login`,
};

interface IUrlConst {
  change_pass: string;
  update_email: string;
  update_phone: string;
  signin_complete_profile: string;
}
