import {
  HttpClient
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map, take } from 'rxjs/operators';

import { LoadingBarService } from '../../modules/shared/services/loading-bar.service';
import {
  ApiMessagesResponse,
  Message,
  MessagesFilters,
  NotificationCount,
} from 'src/app/modules/shared/interfaces/messages';
import { AuthService } from 'src/app/modules/auth';

@Injectable({
  providedIn: 'root',
})
export class MessagesApiService {
  apiUrl: string;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    @Inject('API_URL') apiUrl,
    protected loadingBarService: LoadingBarService
  ) {
    this.apiUrl = apiUrl;
  }

  getMessages(
    type: string = 'inbox',
    filters: MessagesFilters = {}
  ): Observable<ApiMessagesResponse> {
    const { page, perPage, query, sortBy, sortDirection } = filters;

    const params: any = {};
    if (query) {
      params.searchText = query;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (sortDirection) {
      params.sortDirection = sortDirection;
    }

    return this.http
      .get(
        this.apiUrl + `/Message/${type}/${page * perPage - perPage}/${perPage}`,
        { params }
      )
      .pipe(
        take(1),
        map((res: any) => {
          return res;
        }),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }

  getMessage(messageId: string, type: string = 'inbox'): Observable<Message> {
    return this.http
      .get(this.apiUrl + `/Message/${type}/Read/${messageId}`)
      .pipe(
        retry(1),
        map((res: any) => res),
        catchError((err) => {
          return of({
            data: [],
            total: 0
          })
        })
      );
  }

  getCount(): Observable<NotificationCount> {
    return this.http.get(this.apiUrl + `/Message/NotificationCount`).pipe(
      retry(1),
      map((res: NotificationCount) => res),
      catchError((err) => {
        this.handleError(err, this);
        return of({
          unreadDeleted: 0,
          unreadDraft: 0,
          unreadInbox: 0,
          unreadSent: 0,
        });
      })
    );
  }

  replyMessage(parentId: string, message: Message) {
    const formData: FormData = new FormData();
    message.attachments.forEach((attachment) =>
      formData.append('files', attachment)
    );
    formData.set('body', message.body);
    formData.set('subject', message.subject);
    formData.set('parentMessageId', parentId);

    const requestOptions: Object = {
      responseType: 'text',
    };

    return this.http
      .post(this.apiUrl + '/Message/ReplyMessage', formData, requestOptions)
      .pipe(
        retry(1),
        //map((res: any) => res.data),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }

  deleteInboxMessages(ids: string[]): Observable<boolean> {
    return this.http
      .post(this.apiUrl + '/Message/Inbox/Delete', ids, {
        responseType: 'text',
      })
      .pipe(
        retry(1),
        map((res: any) => res),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }

  deleteDeletedMessages(ids: string[]): Observable<boolean> {
    return this.http
      .post(this.apiUrl + '/Message/Deleted/MoveToArchive', ids, {
        responseType: 'text',
      })
      .pipe(
        retry(1),
        map((res: any) => res),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }

  restoreDeletedMessages(ids: string[]): Observable<boolean> {
    return this.http
      .post(this.apiUrl + '/Message/Deleted/Restore', ids, {
        responseType: 'text',
      })
      .pipe(
        retry(1),
        map((res: any) => !!res),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }

  getSubjects(): Observable<string[]> {
    return this.http.get(this.apiUrl + '/Message/Subjects').pipe(
      retry(1),
      map((r: string[]) => r),
      catchError((err) => {
        return this.handleError(err, this);
      })
    );
  }

  getNotificationCount(): Observable<number> {
    /* return this.http.get(this.apiUrl + '/Message/NotificationCount')
      .pipe(
        retry(1),
        map((r: number) => r),
        catchError(err => { return this.handleError(err, this) })
      ); */
    return of(0);
  }

  getSearchSuggestions(query: string): any {
    return this.http
      .get(this.apiUrl + `/Message/SearchSuggestions?searchKey=${query}`)
      .pipe(
        retry(1),
        map((r: any) => r),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }

  getSearchSuggestionsv1(query: string): any {
    return this.http
      .get(this.apiUrl + `/Message/SearchSuggestionsV1?searchKey=${query}`)
      .pipe(
        retry(1),
        map((r: any) => r),
        catchError((err) => {
          return this.handleError(err, this);
        })
      );
  }
  // Error handling
  handleError(error, self) {

    //this.loadingBarService.setLoading(false);
    let errorMessage = '';

    if (error?.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      if (error.status === 401) {
        this.authService.logout();
        return throwError(errorMessage);
      }
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }
}
