import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxFilesizeModule } from 'ngx-filesize';
import { CommonModule } from '@angular/common';

import { AuthService } from './modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './core/http-interceptors/auth-interceptor';
import { AppMonitoringService } from './core/services/app-monitoring.service';
import { SharedModule } from './modules/shared/shared.module';
import { DateOrTimePipe } from './core/filters/date.filter';
import { SettingsComponent } from './modules/settings/settings.component';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { BreakpointObserverService } from './core/services/breakpoint-oberver.service';

import { DialogModelComponent } from './modules/dialog-model/dialog.component';
import { GenericUtilities } from './modules/shared/generic-methods/generic-methods';
import { QuoteModule } from './modules/quote/quote.module';
import { AuthModule } from './modules/auth/auth.module';
import { firstValueFrom } from 'rxjs';
import { ErrorsModule } from './modules/errors/errors.module';
import { RouterModule } from '@angular/router';
import { InteractionModule } from './modules/activity/interaction.module';
import { StoreModule } from '@ngrx/store';
import { metaReducers, messageReducer } from './reducers';
import { EffectsModule } from '@ngrx/effects';
// #fake-end#

function appInitializer(authService: AuthService) {
    return () => {
        return firstValueFrom(authService.getUserByToken());
    };
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        DateOrTimePipe,
        SettingsComponent,
        DialogModelComponent,
    ],
    bootstrap: [AppComponent], imports: [RouterModule,
        BrowserAnimationsModule,
        MatDialogModule,
        SplashScreenModule,
        ClipboardModule,
        MatExpansionModule,
        AuthModule,
        QuoteModule,
        MatButtonModule,
        MatSelectModule,
        MatTabsModule,
        MatBadgeModule,
        MatIconModule,
        ToastrModule.forRoot(),
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        SharedModule,
        NgxFilesizeModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorsModule,
        InteractionModule,
        StoreModule.forRoot({ message: messageReducer }),
        EffectsModule.forRoot([])
        ], providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: appInitializer,
                multi: true,
                deps: [AuthService],
            },
            {
                provide: HIGHLIGHT_OPTIONS,
                useValue: {
                    coreLibraryLoader: () => import('highlight.js/lib/core'),
                    languages: {
                        xml: () => import('highlight.js/lib/languages/xml'),
                        typescript: () => import('highlight.js/lib/languages/typescript'),
                        scss: () => import('highlight.js/lib/languages/scss'),
                        json: () => import('highlight.js/lib/languages/json'),
                    },
                },
            },
            { provide: 'API_URL', useValue: environment.apiUrl },
            { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
            AppMonitoringService,
            GenericUtilities,
            BreakpointObserverService,
            NgbActiveModal,
            provideEnvironmentNgxMask(),
            provideHttpClient(withInterceptorsFromDi()),
        ]
})
export class AppModule { }
