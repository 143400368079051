import { DialogModelComponent } from 'src/app/modules/dialog-model/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { urlConst } from '../../settings/url.const';
@Injectable({
  providedIn: 'root',
})
export class GenericUtilities {
  dialogRef: any;
  constructor(private dialog?: MatDialog) {}
  showConfirmDialog(
    title: String,
    content: string,
    buttonLeft: string,
    buttonRight: string,
    onClose: (ref: any) => void
  ) {
    this.dialogRef = this.dialog.open(DialogModelComponent, {
      width: '239px',
      height: '135px',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'dialog-overlay',
      data: {
        title: title,
        content: content,
        buttonLeft: buttonLeft,
        buttonRight: buttonRight,
      },
    });
    onClose(this.dialogRef);
    // dialogRef.afterClosed().subscribe((result) => {});
  }

  goToB2cUpdate(navLink: string, username: string, maskedEmail: string = '', maskedPhone) {
    let toNav: string = '';
    switch (navLink) {
      case 'change-password':
        toNav = `${urlConst.change_pass}&username=${username}&masked_email=${maskedEmail}&masked_phone=${maskedPhone}`;
        break;
      case 'update-email':
        toNav = `${urlConst.update_email}&username=${username}&masked_email=${maskedEmail}&masked_phone=${maskedPhone}`;
        break;
      case 'update-phone':
        toNav = `${urlConst.update_phone}&username=${username}&masked_email=${maskedEmail}&masked_phone=${maskedPhone}`;
        break;
    }

    window.open(toNav, '_self');
  }
  maskEmaill(email: any) {
    const memail: any = `${email[0]}****${email.substr(
      email.indexOf('@') - 1,
      email.length
    )}`;
    return memail;
  }
  maskPhone(phone: any) {
    const mphone = `${phone.substr(0, 2)}****${phone.substr(
      phone.length - 3,
      phone.length
    )}`;
    return mphone;
  }
}
